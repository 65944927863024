import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDonorSampleRemainderLabData } from '../interfaces/donor-sample-remainder-lab-data';

export interface IDonorSampleRemainderLabDataDynamicControlsParameters {
    formGroup?: string;
}

export class DonorSampleRemainderLabDataDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donorsampleremainderlabdata?: IDonorSampleRemainderLabData, additionalParameters?: IDonorSampleRemainderLabDataDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DonorSampleRemainderLabData';

        this.Form = {
            LabelVerifiedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Label Verified By',
                name: 'LabelVerifiedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.hasOwnProperty('LabelVerifiedBy') && this.donorsampleremainderlabdata.LabelVerifiedBy !== null ? this.donorsampleremainderlabdata.LabelVerifiedBy.toString() : '',
            }),
            Motility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Motility',
                name: 'Motility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.min(0), Validators.max(99) ],
                validators: { 'min': 0, 'max': 99 },
                value: this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.Motility || null,
            }),
            TestThawMotileConcentration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Test Thaw Motile Concentration',
                name: 'TestThawMotileConcentration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.TestThawMotileConcentration || null,
            }),
            Volume: new DynamicField({
                formGroup: this.formGroup,
                label: 'Volume',
                name: 'Volume',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.Volume || null,
            }),
        };

        this.View = {
            LabelVerifiedBy: new DynamicLabel(
                'Label Verified By',
                this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.hasOwnProperty('LabelVerifiedBy') && this.donorsampleremainderlabdata.LabelVerifiedBy !== null ? this.donorsampleremainderlabdata.LabelVerifiedBy.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Motility: new DynamicLabel(
                'Motility',
                this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.Motility || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale:null,
                }),
            ),
            TestThawMotileConcentration: new DynamicLabel(
                'Test Thaw Motile Concentration',
                this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.TestThawMotileConcentration || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            Volume: new DynamicLabel(
                'Volume',
                this.donorsampleremainderlabdata && this.donorsampleremainderlabdata.Volume || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
        };

    }
}
