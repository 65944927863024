<div class="mile-editable miles-card padded">
    <div *ngIf="!isEditing">
        <h4>
            Lab Data: Test Thaw Info
            <span class="pull-right" *ngIf="canEdit" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <div *ngIf="formCreated">
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.TestThawMotileConcentration"></dynamic-label>
            <dynamic-label [field]="abstractDonorTestSampleLabDataControls.Motility"></dynamic-label>
            <div class="display-padded">
                <b><label>TMC Test Vial Per Sample (x10<sup>6</sup>/ml)</label>:</b>
                <span>{{ calculatedFields.TMCTestVialPerSample }}</span>
            </div>
            <div class="display-padded">
                <b><label>TMC (All Vials) (x10<sup>6</sup>/ml)</label>:</b>
                <span>{{ calculatedFields.TMCAllVials }}</span>
            </div>
            <div class="display-padded">
                <b><label>Survival</label>:</b>
                <span>{{ calculatedFields.Survival }}</span>
            </div>
            <div class="display-padded">
                <b><label>Overall Efficiency</label>:</b>
                <span>{{ calculatedFields.OverallEfficiency }}</span>
            </div>
            <dynamic-label *ngIf="!isRemainderData" [field]="abstractDonorTestSampleLabDataControls.TestThawTech"></dynamic-label>
        </div>
    </div>
    <div *ngIf="isEditing">
        <form *ngIf="formCreated" [formGroup]="donorTestSampleLabDataForm" (ngSubmit)="formSubmitted()">
            <div>
                <h4>Lab Data: Test Thaw Info</h4>
                <dynamic-field
                    [field]="abstractDonorTestSampleLabDataControls.TestThawMotileConcentration"
                    [form]="donorTestSampleLabDataForm"
                ></dynamic-field>
                <dynamic-field
                    [field]="abstractDonorTestSampleLabDataControls.Motility"
                    [form]="donorTestSampleLabDataForm"
                ></dynamic-field>
                <div class="calculated-field">
                    <b>TMC Test Vial Per Sample (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled value="{{ calculatedFields.TMCTestVialPerSample }}" />
                </div>
                <div class="calculated-field">
                    <b>TMC (All Vials) (x10<sup>6</sup>/ml)</b>
                    <input class="form-control" disabled value="{{ calculatedFields.TMCAllVials }}" />
                </div>
                <div class="calculated-field">
                    <b>Survival</b>
                    <input class="form-control" disabled value="{{ calculatedFields.Survival }}" />
                </div>
                <div class="calculated-field">
                    <b>Overall Efficiency</b>
                    <input class="form-control" disabled value="{{ calculatedFields.OverallEfficiency }}" />
                </div>
                <dynamic-field
                    *ngIf="!isRemainderData"
                    [field]="abstractDonorTestSampleLabDataControls.TestThawTech"
                    [form]="donorTestSampleLabDataForm"
                ></dynamic-field>
            </div>
            <div>
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </form>
    </div>
</div>
